<template>
	<v-card class="mx-auto" max-width="900" outlined>
		<v-row class="ma-0 pa-0 d-flex flex-row">
			<v-col cols="3" class="pa-0">
				<div class="image-wrapper d-flex align-center justify-center">
					<v-img
						:src="caseImage.publicURL"
						v-if="caseImage !== null"
					></v-img>

					<p v-else class="font-weight-bold">No Image Uploaded</p>
				</div>
			</v-col>
			<v-col cols="9" class="px-4 py-3">
				<div class="d-flex align-start justify-space-between">
					<h3 class="title" color="#5F615A" v-if="published">
						<a :href="url" target="_blank">{{ title }}</a>
					</h3>
					<h3 class="title" color="#5F615A" v-else>
						{{ title }}
					</h3>
					<StatusChips :is-published="published"></StatusChips>
				</div>
				<div class="d-flex flex-row" v-if="symptoms.length > 0">
					<p class="pr-1">Symptoms :</p>
					<v-chip
						v-for="(symptom, index) in symptoms"
						:key="symptom"
						class="mr-2 chip mb-2"
					>
						<p v-if="index < 3">{{ symptom }}</p>
						<span v-if="index >= 3" class="more pa-0">
							+ {{ symptoms.length - index }} more
						</span>
					</v-chip>
				</div>
				<div class="d-flex flex-row" v-if="diagnosis.length > 0">
					<p class="pr-1">Diagnosis :</p>
					<v-chip
						v-for="(diagnos, index) in diagnosis"
						:key="diagnos"
						class="mr-2 chip mb-2"
					>
						<p v-if="index < 3">{{ diagnos }}</p>
						<span v-if="index >= 3" class="more pa-0">
							+ {{ diagnosis.length - index }} more
						</span>
					</v-chip>
				</div>
				<div class="d-flex flex-row" v-if="testsPerformed.length > 0">
					<p class="pr-1">Tests Performed :</p>
					<v-chip
						v-for="(testPerformed, index) in testsPerformed"
						:key="testPerformed"
						class="mr-2 chip mb-2"
					>
						<p v-if="index < 3">{{ testPerformed }}</p>
						<span v-if="index >= 3" class="more pa-0">
							+ {{ testsPerformed.length - index }} more
						</span>
					</v-chip>
				</div>
			</v-col>
		</v-row>

		<v-divider></v-divider>

		<!--Action Buttons-->
		<v-card-actions class="px-6 py-4 card-actions">
			<UnoEditButton
				v-if="$can('update', 'CaseStudy')"
				:to="{ name: 'case-study.edit', params: { id } }"
			></UnoEditButton>

			<UnoPublishButton
				v-if="$can('publish', 'CaseStudy')"
				:isPublished="published"
				@click.native="toggleStatus()"
			></UnoPublishButton>

			<v-spacer></v-spacer>
			<UnoDeleteButton
				v-if="$can('delete', 'CaseStudy')"
				:deleting="deleting"
				@confirm="deleteCaseStudy()"
			></UnoDeleteButton>
		</v-card-actions>
	</v-card>
</template>

<script>
// ACTION BUTTONS
import UnoPublishButton from '@/components/Buttons/UnoPublishButton'
import UnoEditButton from '@/components/Buttons/UnoEditButton'
import UnoDeleteButton from '@/components/Buttons/UnoDeleteButton'
import StatusChips from '@/components/StatusChips'

export default {
	data() {
		return {
			deleting: false,
			published: this.isPublished,
		}
	},
	props: {
		id: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		detailedDescription: {
			type: String,
		},
		isPublished: {
			type: Boolean,
			required: true,
		},
		symptoms: {
			type: Array,
			required: true,
		},
		services: {
			type: Array,
			required: true,
		},
		testsPerformed: {
			type: Array,
			required: true,
		},
		diagnosis: {
			type: Array,
			required: true,
		},
		caseImage: {
			type: Object,
		},
		slug: {
			type: String,
			required: true,
		},
	},
	components: {
		UnoEditButton,
		UnoPublishButton,
		UnoDeleteButton,
		StatusChips,
	},

	computed: {
		url() {
			let domain = this.$store.getters['app/getCurrentProjectDomain']
			let caseStudyUrl = `http://${domain}/case-study/${this.slug}`
			return caseStudyUrl
		},
	},
	methods: {
		editCaseStudy(id) {
			this.$router.push({
				name: 'case-study.edit',
				params: { id: id },
			})
		},
		deleteCaseStudy() {
			this.deleting = true
			const projectId = this.$store.state.app.projectId
			this.axios
				.delete(`/${projectId}/case-studies/${this.id}`)
				.then(() => this.$emit('updateCaseStudy', this.id))
			this.deleting = false
		},
		toggleStatus() {
			const projectId = this.$store.state.app.projectId

			this.axios({
				method: 'post',
				url: `/${projectId}/case-studies/toggle-status/${this.id}`,
			}).then(() => {
				this.published = !this.published
			})
		},
	},
}
</script>
<style scoped>
.image-wrapper {
	overflow: hidden;
	border: 1px solid #eee;
	max-height: 200px !important;
	height: 100%;
}
.image-wrapper .v-responsive {
	height: 100% !important;
}
.title a {
	font-size: 21px !important;
	line-height: 1.4 !important;
	color: black !important;
	text-decoration: none !important;
}
.title a:hover {
	color: var(--blue) !important;
}
</style>
