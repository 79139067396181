<template>
	<v-container v-if="$can('view', 'CaseStudy')" fluid>
		<div v-if="$can('create', 'CaseStudy')" class="text-right">
			<v-btn rounded color="primary" dark @click="createCaseStudy()">
				+ Create Case Study
			</v-btn>
		</div>

		<div v-if="caseStudies.length > 0" class="mt-4">
			<CaseStudyCard
				v-for="caseStudy in caseStudies"
				:key="caseStudy.id"
				v-bind="caseStudy"
				@updateCaseStudy="updateCaseStudyListing($event)"
			></CaseStudyCard>
		</div>

		<NoData v-else></NoData>

		<Paginator
			:paginator="paginator"
			:url="getUrl()"
			@update="changePage($event)"
		></Paginator>
	</v-container>
</template>

<script>
import Paginator from '../../components/Paginator'
import CaseStudyCard from './components/CaseStudyCard'
import NoData from '../../components/NoData'
export default {
	data() {
		return {
			caseStudies: [],
			paginator: {},
		}
	},
	components: {
		CaseStudyCard,
		NoData,
		Paginator,
	},
	created() {
		this.getListingData()
	},

	methods: {
		getListingData() {
			const projectId = this.$store.state.app.projectId

			this.axios({
				method: 'get',
				url: `/${projectId}/case-studies`,
			}).then((response) => {
				let _data = response.data
				this.caseStudies = _data.data
				this.paginator = _data.paginator
			})
		},

		getUrl() {
			const projectId = this.$store.state.app.projectId
			let url = `/${projectId}/case-studies`
			return url
		},
		changePage(data) {
			this.caseStudies = data.data
			this.paginator = data.paginator
		},
		createCaseStudy() {
			this.$router.push({
				name: 'case-study.create',
			})
		},
		updateCaseStudyListing(caseStudyId) {
			const caseStudyArray = this.caseStudies
			caseStudyArray.forEach((caseStudy, index) => {
				if (caseStudy.id === caseStudyId) {
					caseStudyArray.splice(index, 1)
					return true
				} else {
					return false
				}
			})
			this.caseStudies = caseStudyArray
		},
	},
}
</script>
